import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    useGridApiContext
} from "@mui/x-data-grid";
import {
    Button,
} from "@mui/material";
import React from "react";
import {CopyAll} from "@mui/icons-material";

export const GridToolbarButton = ({startIcon, label, onClick, disabled}) => {
    return (
        <Button size="small" startIcon={startIcon} onClick={onClick} disabled={disabled}>
            {label}
        </Button>
    )
}

const CustomToolbar = ({buttons = [], totalExport = true}) => {
    const apiRef = useGridApiContext();
    const handleExport = (options) =>
        apiRef.current.exportDataAsCsv(options);

    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport csvOptions={{utf8WithBom: true}}/>
            {buttons?.map((button, index) => <div key={index}>{button}</div>) /** eventuali buttons aggiuntivi custom */}
        </GridToolbarContainer>
    );
}

export default CustomToolbar
