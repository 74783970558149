import React from "react";
import {Box, Button, Stack} from "@mui/material";
import {BasicInfo} from "./EventForm/BasicInfo";
import {RealTimeDataInfo} from "./EditEvent/RealTimeDataInfo";
import axios from "axios";
import {API_URL} from "../../config";
import {CustomAlert} from "../shared/CustomAlert";

export const EventForm = ({handleOpen}) => {
    const [data, setData] = React.useState({
        name:'',
        num_participants: '',
        total_tables: '',
        total_hours: '',
        placeDateTime:"",
        max_sentence_len: '',
        start_at: null,
        end_at: null,
    });
    const [success, setSuccess] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [errorText, setErrorText] = React.useState('')

    const handleChange = (id, value) => {
        setData({...data, [id]: value})
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let _data = data
        _data.id = data["name"].toLowerCase().replaceAll(' ', '-')
        await axios.post(`${API_URL}events`, data)
            .then(() => {
                setSuccess(true)
                handleOpen()
            })
            .catch()
    }

    return (
        <Box>
            <CustomAlert open={success} severity={'success'} message={'Evento creato con successo'}/>
            <CustomAlert open={error} severity={'error'} message={errorText!=='' ? errorText : 'Errore durante la creazione dell\'evento'}/>
            <form onSubmit={handleSubmit}>
                <BasicInfo handleChange={handleChange} data={data}/>
                <Stack direction={'row'} justifyContent={'right'} spacing={2} sx={{pt:2}}>
                    <Box>
                        <Button color={'error'} onClick={handleOpen}>
                            Indietro
                        </Button>
                    </Box>
                    <Box>
                        <Button variant={'contained'} type={'submit'}>
                            Aggiungi
                        </Button>
                    </Box>
                </Stack>
            </form>
        </Box>
    )
}