import React, {useState} from "react";
import {
    Backdrop,
    Badge,
    Box,
    Button, CircularProgress,
    Container,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    Tab,
    Typography
} from "@mui/material";
import {DataGrid} from '@mui/x-data-grid';
import UserService from "../services/user.service";
import {AutoFixHigh, ContentCopy, CopyAll, Refresh, Settings, SsidChart} from "@mui/icons-material";
import CustomGridToolbar, {GridToolbarButton} from "./CustomGridToolbar";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {useParams} from "react-router-dom";
import {marked} from "marked";
import DOMPurify from 'dompurify';

function formatText(input) {
    // Sostituisce i segnaposto per i titoli con i tag markdown corrispondenti
    let formattedText = input.replace(/### (.+?)(?=\n|$)/g, '### $1');

    // Sostituisce i segnaposto per il grassetto con i tag markdown corrispondenti
    formattedText = formattedText.replace(/\*\*(.+?)\*\*/g, '**$1**');

    return formattedText;
}

const TextRenderer = ({ inputText }) => {
    const html = marked(inputText);
    const cleanHtml = DOMPurify.sanitize(html);

    return <div dangerouslySetInnerHTML={{ __html: cleanHtml }} />;
};


function AISummaryDialog({session, refetchEvent}) {
    const {event_id} = useParams()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [summaryAI, setSummaryAI] = useState(formatText(session?.summaryAI ?? ""))

    const fetchAISummary = async () => {
        setLoading(true)
        const summary = await UserService.getSummary(event_id, session.id)

        setSummaryAI(formatText(summary?.data?.message?.content ?? ""))

        setLoading(false)
    }

    function handleOpen() {
        setOpen(true)

        if(!session.summaryAI) {
            fetchAISummary()
        }
    }

    function handleClose() {
        if(summaryAI !== session.summaryAI)
            refetchEvent()

        setOpen(false)
    }

    async function handleCopy(event) {
        event.stopPropagation()
        await navigator.clipboard.writeText(summaryAI)
            .then(() => window.alert("Riepilogo copiato!"))
    }

    return (
        <Stack>
            <Button variant={'outlined'} onClick={handleOpen} startIcon={<AutoFixHigh/>}>
                Riassunto AI
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <Backdrop open={loading} children={<CircularProgress/>}/>
                <DialogTitle>Riassunto AI</DialogTitle>
                <DialogContent>
                    <TextRenderer inputText={summaryAI}/>
                </DialogContent>
                <Stack p={1} direction={'row'} justifyContent={'space-between'}>
                    <Button variant={'outlined'} disabled={loading || !summaryAI} onClick={fetchAISummary} startIcon={<AutoFixHigh/>}>
                        Rigenera Riassunto AI
                    </Button>
                    <Stack direction={"row"}>
                        <Button onClick={handleClose} disabled={loading}>
                            Chiudi
                        </Button>
                        <Button variant={'contained'} onClick={handleCopy} disabled={loading} startIcon={<ContentCopy/>}>
                            Copia
                        </Button>
                    </Stack>
                </Stack>
            </Dialog>
        </Stack>
    );
}

const FrasiEvento = ({...props}) => {
    const {user, handleOpen, eventInfo, tab, handleTabChange, copiaFrasi, updateData, loading, frasiSelection, handleSelectionChange, columns, frasi, frasiCopiate, refetchEvent} = props;
    const [pageSize, setPageSize] = useState(100)
    const csvOptions = {allColumns: true, utf8WithBom: true}

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
        Cluster: false,
    });

    const [filterModel, setFilterModel] = useState({
        items: [],
    });

    return (
        <Container sx={{paddingTop: '2rem'}} maxWidth={false}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack direction={'row'} alignItems={'center'} spacing={3}>
                    <Typography variant={"h4"} sx={{textAlign: 'left'}}>
                        {eventInfo?.name}
                    </Typography>
                </Stack>
                {user &&
                    <Stack direction={'row'} spacing={2}>
                        <Box>
                            <Button variant={'contained'} startIcon={<Settings/>} onClick={() => handleOpen('impostazioni')}>
                                Impostazioni Evento
                            </Button>
                        </Box>
                        <Box>
                            <Button disabled variant={'contained'} startIcon={<SsidChart/>} onClick={() => handleOpen('realTime')}>
                                Real time data
                            </Button>
                        </Box>
                    </Stack>
                }
            </Stack>

            <Divider/>

            <TabContext value={tab}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleTabChange} aria-label="Frasi">
                        {eventInfo?.sessions?.sort((a, b) => a.position - b.position).map((s) => (
                            <Tab label={s.name} value={s.id} key={s.id}/>
                        ))}
                    </TabList>
                </Box>
                {eventInfo?.sessions?.sort((a, b) => a.position - b.position).map((s, key) => {
                    return <TabPanel value={s.id} key={key}>
                        <Container maxWidth={false}>
                            {eventInfo?.sessions?.find((s) => s.id === tab)?.questions?.sort((a, b) => a.position - b.position).map((q, key) =>
                                <Typography variant={"body1"} sx={{mb: 2}} key={key}>
                                    <Badge color={key === 0 ? "primary" : (key === 1 ? "info" : (key === 2 ? "warning" : "success"))}
                                           overlap={"circular"} badgeContent={key + 1} sx={{mr: 2}}></Badge>
                                    {q.content}
                                </Typography>
                            )}
                        </Container>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
                            <Button variant={'contained'} onClick={() => updateData()} startIcon={<Refresh/>}>
                                Aggiorna
                            </Button>
                            <AISummaryDialog session={eventInfo?.sessions?.find((s) => s.id === tab)} refetchEvent={props.refetchEvent}/>
                        </Stack>
                        <div style={{display: 'flex', height: 670, marginTop: '1rem'}} id={"tabpanel-" + key}>
                            <div style={{flexGrow: 1}}>
                                <DataGrid
                                    filterModel={filterModel}
                                    onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                                    components={{
                                        Toolbar: () => {
                                            return <Stack sx={{p:1}} direction={'row'} justifyContent={'space-between'}>
                                                <CustomGridToolbar buttons={[
                                                    <GridToolbarButton onClick={() => copiaFrasi(tab)}
                                                                       startIcon={<CopyAll/>}
                                                                       label={"Copia Selezionate"}/>
                                                ]}/>
                                            </Stack>
                                        }
                                    }}
                                    loading={loading}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel) =>
                                        setColumnVisibilityModel(newModel)
                                    }
                                    initialState={{
                                        sorting: {
                                            sortModel: [{field: 'updated_at', sort: 'asc'}],
                                        },
                                    }}
                                    getRowId={(row) => row.id}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    selectionModel={frasiSelection[tab]}
                                    onSelectionModelChange={(newSelectionModel) => {
                                        handleSelectionChange(tab, newSelectionModel);
                                    }}
                                    componentsProps={{toolbar: {csvOptions}}}
                                    /* components={{Toolbar: GridToolbar}} */
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    rowsPerPageOptions={[100]}
                                    columns={columns}
                                    rows={frasi[tab]}
                                    getRowHeight={() => 'auto'}
                                    getRowClassName={(params) => frasiCopiate.includes(params.id) ? 'bg-success' : ''}
                                />
                            </div>
                        </div>
                    </TabPanel>
                })}
            </TabContext>
        </Container>
    )
}

    export default FrasiEvento
