import React, {useEffect, useState} from "react";
import {
    Badge,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    IconButton,
    Stack,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {useCollection} from "react-firebase-hooks/firestore";
import {firestore} from "../../../../firebase/clientApp";
import {useParams} from "react-router-dom";
import {Add, DeleteForever} from "@mui/icons-material";
import axios from "axios";
import {API_URL} from "../../../../config";
import {LoaderButton} from "../../../shared/LoaderButton";
import {CustomAlert} from "../../../shared/CustomAlert";

export const SessionSettings = ({_session, currentSessions, eventInfo}) => {
    const {event_id} = useParams()
    const [currentQuestions, loadingQuestions, errorQuestions] = useCollection(firestore.collection('events/' + event_id + '/sessions/' + _session.id + '/questions').orderBy('position'))
    const theme = useTheme()
    const [session, setSession] = useState(_session)
    const [adding, setAdding] = useState(false)
    const [deletingSession, setDeletingSession] = useState(false)
    const [deletingQuestion, setDeletingQuestion] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (currentQuestions && !loadingQuestions && !errorQuestions) {
            let _questions = currentQuestions.docs.map((q, index) => {
                let _question = {
                    id: q.id,
                    ...q.data()
                }
                if (!q.position){
                    _question.position = index
                }
                return _question
            })
            _questions.sort((a, b) => a.position - b.position)
            setSession({
                ...session,
                questions: _questions
            })
        }
    }, [currentQuestions, loadingQuestions, errorQuestions, _session])

    const addQuestion = async () => {
        setAdding(true)
        await axios.post(`${API_URL}events/${event_id}/sessions/${session.id}/questions`, {position: session.questions.length})
            .then((res) => {
                setSuccess(true)
            })
            .catch((err) => {
                setError(true)
            })
        setAdding(false)
    }

    const handleChangeQuestionData = (position, field, value) => {
        let _questions = session.questions.map((q) => {
            if (q.position === position) {
                return ({
                    ...q,
                    [field]: value
                })
            } else {
                return q
            }
        })

        setSession({
            ...session,
            questions: _questions
        })
    }

    const deleteQuestion = async (id) => {
        setDeletingQuestion(true)
        await axios.delete(`${API_URL}events/${event_id}/sessions/${session.id}/questions/${id}`)
            .then()
            .catch()
        setDeletingQuestion(false)

    }

    const updateSession = async (e) => {
        e.preventDefault()
        setUpdating(true)
        await axios.put(`${API_URL}events/${event_id}/sessions/${session.id}`, session)
            .then((res) => {
                setSuccess(true)
            })
            .catch((err) => {
                setError(true)
            })
        setUpdating(false)
    }

    const deleteSession = async (id) => {
        setDeletingSession(true)
        await axios.delete(`${API_URL}events/${event_id}/sessions/${id}`)
            .then()
            .catch()
        setDeletingSession(false)
    }

    if (loadingQuestions) return (<Badge color={'error'}>Caricamento...</Badge>)

    return (
        <Card sx={{border: `1px solid ${theme.palette.primary.main}`}}>
            <CustomAlert open={success} severity={'success'} message={'Sessione aggiornata correttamente'} handleClose={() => setSuccess(false)}/>
            <CustomAlert open={error} severity={'error'} message={'Errore durante l\'aggiornamento della sessione'} handleClose={() => setError(false)}/>
            <CardContent>
                <Box component={'form'} onSubmit={updateSession}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography fontWeight={'bold'} fontSize={18}>
                            Sessione {session.position + 1}
                        </Typography>
                        <Box sx={{position: 'relative'}}>
                            {deletingSession && <LoaderButton/>}
                            <Button disabled={deletingSession || eventInfo.data()['current_session'] === session.id } variant={'outlined'} color={'error'} onClick={() => deleteSession(session.id)} startIcon={<DeleteForever/>}>
                                Elimina sessione
                            </Button>
                        </Box>
                    </Stack>
                    <Stack spacing={2}>
                        <TextField required value={session.name} label={'Nome'} variant={"standard"}
                                   onChange={(e) => setSession({...session, name:e.target.value})}/>
                        <TextField value={session.description} label={'Descrizione'} multiline minRows={3}
                                   variant={"outlined"}
                                   onChange={(e) => setSession({...session, description:e.target.value})}/>
                    </Stack>
                    <Typography fontWeight={'bold'} sx={{pt: 2}}>
                        Domande
                    </Typography>
                    <Stack spacing={2}>
                        {
                            session?.questions?.length > 0 && !loadingQuestions && !errorQuestions && session.questions.map((question, index) =>
                                <Stack direction={'row'} alignItems={'end'} spacing={2} key={index}>
                                    <Box sx={{position:'relative'}}>
                                        {deletingQuestion && <LoaderButton/>}
                                        <IconButton disabled={deletingQuestion} color={'error'} onClick={() => deleteQuestion(question.id)}>
                                            <DeleteForever/>
                                        </IconButton>
                                    </Box>
                                    <Box sx={{flexGrow:1}}>
                                        <TextField required fullWidth key={index} value={question.content} label={'Domanda'} variant={"standard"}
                                            onChange={(e) => handleChangeQuestionData(question.position, 'content', e.target.value)}
                                        />
                                    </Box>
                                </Stack>
                            )
                        }
                    </Stack>
                    <Box sx={{position:'relative', pt:2}}>
                        <Button disabled={adding} startIcon={<Add/>} onClick={addQuestion} variant={'outlined'}>
                            Aggiungi domanda
                        </Button>
                    </Box>
                    <Box sx={{mt:1, textAlign:'center', position:'relative'}}>
                        {updating && <LoaderButton/>}
                        <Button disabled={updating} variant={'contained'} type={'submit'}>
                            Salva
                        </Button>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}