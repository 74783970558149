import React from "react";
import {
    AppBar,
    Box,
    Button, CircularProgress,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Stack, TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import {Login, Logout} from "@mui/icons-material";
import {auth} from "../../firebase/clientApp";
import {CustomAlert} from "./CustomAlert";

export const Header = () => {
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState({email:'', password:''});
    const [error, setError] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleOpen = () => setOpen( !open );

    const handleError = (e) => setError( !error);

    const handleSuccess = (e) => setSuccess( !success);

    const doLogin = async (e) => {
        e.preventDefault()
        setLoading(true)
        await auth.signInWithEmailAndPassword(data.email, data.password)
            .then((res) => {
                setSuccess(true)
                handleOpen()
            })
            .catch((err) => {
                setError(true)
            })
        setLoading(false)
    }

    const doLogout = async (e) => {
        e.preventDefault()
        setLoading(true)
        await auth.signOut()
        setLoading(false)
    }

    return (
        <Box>
            <CustomAlert open={error} handleClose={handleError} severity={'error'} message={'Credenziali errate'}/>
            <CustomAlert open={success} handleClose={handleSuccess} severity={'success'} message={'Login effettuato'}/>
            <Dialog open={open} onClose={handleOpen} maxWidth={'sm'} fullWidth>
                <DialogTitle>Inserisci le credenziali</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} component={'form'} onSubmit={doLogin}>
                        <TextField label={'Email'} variant={'standard'}
                                   required
                            onChange={(e) => {
                                setData({...data, email:e.target.value})
                            }}
                        />
                        <TextField label={'Password'} type={"password"} variant={'standard'}
                                   required
                                   onChange={(e) => {
                                       setData({...data, password:e.target.value})
                                   }}
                        />
                        <Box sx={{textAlign:'center'}}>
                            {loading ? <CircularProgress/> : <Button variant={'contained'} type={'submit'}>
                                Login
                            </Button>}
                        </Box>
                    </Stack>
                </DialogContent>
            </Dialog>
            <AppBar position="static">
                <Toolbar>
                    <Container maxWidth={'xxl'}>
                        <Stack justifyContent={'space-between'} sx={{height:'100%'}} direction={'row'}>
                            <Box>
                                <Link to={'/'} style={{textDecoration:'none', color:'white'}}>
                                    <Typography variant="h6" component="div">
                                        Eventi
                                    </Typography>
                                </Link>
                            </Box>
                            <Box>
                                {auth.currentUser ?
                                    <Button onClick={doLogout} color={'secondary'} variant={'contained'}
                                            startIcon={<Logout/>}>
                                        Logout
                                    </Button>
                                    :
                                    <Button onClick={handleOpen} color={'secondary'} variant={'contained'}
                                         startIcon={<Login/>}>
                                    Login
                                </Button>}
                            </Box>
                        </Stack>
                    </Container>
                </Toolbar>
            </AppBar>
        </Box>
    )
}