import React, {useEffect, useState} from "react";
import {Main} from "../layouts/Main";
import FrasiEvento from "../components/FrasiEvento";
import {auth} from "../firebase/clientApp";
import {
    Badge,
    Box, Button,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import {EventSettings} from "../components/Event/EditEvent/EventSettings";
import {RealTimeDataInfo} from "../components/Event/EditEvent/RealTimeDataInfo";
import {API_URL} from "../config";
import UserService from "../services/user.service";
import {useParams} from "react-router-dom";
import {ContentCopy, DeleteForever} from "@mui/icons-material";
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const Event = () => {
    const [user, setUser] = useState(null);
    const [open, setOpen] = useState('');
    const [eventInfo, setEventInfo] = useState({})
    const [frasi, setFrasi] = useState({})
    const [frasiSelection, setFrasiSelection] = React.useState({});
    const [frasiCopiate, setFrasiCopiate] = useState([]);
    const [tab, setTab] = React.useState("");
    const [loading, setLoading] = useState(true)
    const [openDelete, setOpenDelete] = useState(false)
    const [idFraseToDelete, setIdFraseToDelete] = useState("")
    const [fraseToDelete, setFraseToDelete] = useState("")
    const [eventLoading, setEventLoading] = useState(true)

    function handleTabChange(e, value) {
        setTab(value);
    }

    const {event_id} = useParams();

    const handleOpen = (toOpen) => setOpen(toOpen);

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                setUser(user)
            } else {
                setUser(null)
            }
        });
    }, []);

    useEffect(() => {
        fetchEvent().then()
    }, [])

    useEffect(() => {
        if (eventInfo?.sessions?.length > 0) {
            fetchData().then()
        }
    },[eventInfo])


    const fetchEvent = async () => {
        setEventLoading(true)
        const eventData = await (await fetch(API_URL + 'events/' + event_id)).json();
        setEventInfo(eventData);
        setEventLoading(false)
    }

    async function fetchData() {
        setLoading(true)
        const sessions = eventInfo.sessions.sort((a, b) => a.position - b.position)
        setTab(sessions[0].id)
        let _frasi = {}
        for (const s of sessions) {
            _frasi[s.id] = []
        }
        setFrasi(_frasi)
        for (const s of sessions) {
            const response = await UserService.getFrasi(event_id, s.id)
            _frasi[s.id] = response?.data?.Frasi ?? [];
        }
        setFrasi(_frasi);
        setLoading(false)
    }

    const handleSelectionChange = (tab, selection) => {
        setFrasiSelection(prevState => {
            return {...prevState, [tab]: selection}
        });
    }

    async function updateData() {
        setLoading(true)
        const response = await UserService.getFrasi(event_id, tab)
        setFrasi(prevState => {
            return {...prevState, [tab]: response.data.Frasi}
        });
        setLoading(false)
    }

    function copiaFrasi(tab) {
        const _frasiSelected = frasiSelection[tab].map((e) => frasi[tab].filter((a) => a.id === e)[0].Frase);
        frasiSelection[tab].forEach((f) => {
            if (!frasiCopiate.includes(f)) {
                setFrasiCopiate(prevState => [...prevState, f]);
            }
        })
        navigator.clipboard.writeText(_frasiSelected.join('\n')).then()
    }

    const columns = [
        {field: 'Tavolo', headerName: 'Tavolo'},
        {field: 'Sentimento', headerName: 'Sentiment'},
        {field: 'Frase', headerName: 'Frase', flex: 1},
        {
            field: 'Domanda', headerName: 'Domanda',
            valueGetter: (params) => {
                return params.row.Domanda != null ? parseInt(params.row.Domanda) + 1 : ''
            },
            renderCell: (params) => {
                return params.value &&
                    <Badge color={params.value == 1 ? "primary" : (params.value == 2 ? "info" : (params.value == 3 ? "warning" : "success"))}
                           overlap={"circular"} badgeContent={parseInt(params.value)}></Badge>
            }
        },
        {
            field: 'Cluster', headerName: 'Cluster', flex: 1,
            valueFormatter: (params) => {
                return params.value.toUpperCase()
            }
        },
        {
            field: "action",
            headerName: "Azioni",
            sortable: false,
            width: 250,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    if (!frasiCopiate.includes(params.row.id)) {
                        setFrasiCopiate(prevState => [...prevState, params.row.id]);
                    }
                    navigator.clipboard.writeText(params.row.Frase).then()
                };

                const deleteFrase = async (e) => {
                    setIdFraseToDelete(params.row.id)
                    setFraseToDelete(params.row.Frase)
                    setOpenDelete(true)
                }

                return(
                    <Stack direction={'row'} justifyContent={'center'} spacing={1}>
                        <IconButton onClick={onClick}><ContentCopy/></IconButton>
                        {user && <IconButton onClick={deleteFrase} color={'error'}><DeleteForever/></IconButton>}
                    </Stack>
                );
            }
        },
        {
            field: 'updated_at', headerName: 'Ora', flex: 0.3, valueGetter: (params) => {
                return params.value ?
                    new Date(params.value?._seconds * 1000).toLocaleString('it-IT') : '';
            }
        }
    ];

    const handleCloseDelete = () => {
        setOpenDelete(false)
        setFraseToDelete("")
        setIdFraseToDelete("")
    }

    const handleDeleteFrase = async () => {
        setOpenDelete(false)
        await UserService.deleteFrase(event_id, tab, idFraseToDelete)
            .then(async () => {
                await fetchData()
            })
    }

    return (
        <Main>
            <Dialog open={openDelete} onClose={handleCloseDelete}>
                <DialogContent>
                    <Typography>
                        Sei sicuro di voler eliminare la frase:
                        <br/>
                        <b>{fraseToDelete}</b>
                    </Typography>
                    <DialogActions>
                        <Button onClick={handleDeleteFrase} color={'error'}>
                            Si
                        </Button>
                        <Button onClick={handleCloseDelete} variant={'contained'}>
                            No
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog open={open!==''} onClose={() => handleOpen('')} maxWidth={'sm'} fullWidth fullScreen={open === 'impostazioni'} TransitionComponent={Transition}>
                {open === 'impostazioni' && <EventSettings open={open} handleOpen={handleOpen} eventInfo={eventInfo} updateData={updateData}/>}
                {open === 'realTime' && <RealTimeDataInfo handleOpen={handleOpen}/>}
            </Dialog>
            {
                eventLoading ?
                    <Stack justifyContent={'center'} alignItems={'center'} height={'20rem'}>
                        <CircularProgress/>
                    </Stack>
                    :
                    <FrasiEvento user={user} handleOpen={handleOpen}
                                 eventInfo={eventInfo} tab={tab} handleTabChange={handleTabChange}
                                 copiaFrasi={copiaFrasi} updateData={updateData} loading={loading}
                                 frasiSelection={frasiSelection} handleSelectionChange={handleSelectionChange}
                                 columns={columns} frasi={frasi} frasiCopiate={frasiCopiate}
                                 eventLoading={eventLoading}
                                 refetchEvent={fetchEvent}
                    />
            }
        </Main>
    )
}