import React, {useState} from "react";
import {
    Box,
    Button,
    Card, CardContent,
    Checkbox, DialogContent, DialogTitle,
    FormControlLabel,
    IconButton,
    Stack,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {Delete} from "@mui/icons-material";

const fields = [
    {
        id: "descriptions",
        name: "descriptions",
        label: "Descrizioni",
        type: "array",
    },
    {
        id: "liveDataButton",
        name: "liveDataButton",
        label: "Bottone per i dati in tempo reale",
        type: "checkbox",
    },
    {
        id: "qrButton",
        name: "qrButton",
        label: "QR code per i dati in tempo reale",
        type: "checkbox",
    },
    {
        id: "graphChord",
        name: "graphChord",
        label: "Grafico Chord",
        type: "checkbox",
    },
    {
        id: "graphPie",
        name: "graphPie",
        label: "Grafico Pie",
        type: "checkbox",
    },
    {
        id: "graphRadar",
        name: "graphRadar",
        label: "Grafico Radar",
        type: "checkbox",
    },
    {
        id: "graphSwarmPlot",
        name: "graphSwarmPlot",
        label: "Grafico SwarmPlot",
        type: "checkbox",
    },
    {
        id: "graphTimeLine",
        name: "graphTimeLine",
        label: "Grafico TimeLine",
        type: "checkbox",
    }
]

export const RealTimeDataInfo = ({handleOpen}) => {
    const [data, setData] = useState({
        descriptions: [],
        liveDataButton: true,
        qrButton: true,
        graphChord: true,
        graphPie: true,
        graphRadar: true,
        graphSwarmPlot: true,
        graphTimeLine: true,
    })

    const handleChange = (id, value) => {
        setData({...data, [id]: value})
    }

    const theme = useTheme()

    const addDescription = () => {
        handleChange('descriptions', [...data.descriptions, {body: "", title: ""}])
    }

    const editDescription = (index, field, value) => {
        let descriptions = data.descriptions
        descriptions[index][field] = value
        handleChange('descriptions', descriptions)
    }

    const deleteDescription = (index) => {
        return () => {
            let descriptions = data.descriptions
            descriptions.splice(index, 1)
            handleChange('descriptions', descriptions)
        }
    }

    const submit = (e) => {
        e.preventDefault()
        console.log(data)
    }

    return (
        <Box>
            <DialogTitle>
                Modifica le impostazioni
            </DialogTitle>
            <DialogContent>
                <Typography>
                    Impostazioni real time data
                </Typography>
                <Box sx={{height: '4px', width: '4rem', background: theme.palette.primary.main}}/>
                <Box component={'form'}
                     onSubmit={submit}>
                    {
                        fields.map((f, index) =>
                            <Box key={index}>
                                {
                                    (() => {
                                        switch (f.type) {
                                            case 'checkbox':
                                                return <FormControlLabel
                                                    control={<Checkbox checked={data[f.id]}
                                                                       onChange={(e) => handleChange(f.id, e.target.checked)}/>}
                                                    label={f.label}/>
                                            case 'array':
                                                return <Box sx={{py: 2}}>
                                                    <Typography fontWeight={'bold'}>
                                                        Descrizioni:
                                                    </Typography>
                                                    <Stack spacing={2}>
                                                        {data.descriptions.map((d, i) =>
                                                            <Card>
                                                                <CardContent>
                                                                    <Stack spacing={2} direction={'row'}
                                                                           alignItems={'center'}>
                                                                        <Box>
                                                                            <IconButton onClick={deleteDescription(i)}>
                                                                                <Delete/>
                                                                            </IconButton>
                                                                        </Box>
                                                                        <Box sx={{flexGrow: 1}}>
                                                                            <Stack direction={'column'} spacing={2}>
                                                                                <TextField fullWidth variant={'standard'}
                                                                                           label={'Titolo'} value={d.title}
                                                                                           onChange={(e) => editDescription(i, 'title', e.target.value)}/>
                                                                                <TextField fullWidth multiline minRows={3}
                                                                                           variant={'outlined'}
                                                                                           label={'Contenuto'}
                                                                                           value={d.body}
                                                                                           onChange={(e) => editDescription(i, 'body', e.target.value)}/>
                                                                            </Stack>
                                                                        </Box>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Card>
                                                        )}
                                                        <Box sx={{textAlign: 'center'}}>
                                                            <Button onClick={addDescription} variant={'contained'}>
                                                                Aggiungi descrizione
                                                            </Button>
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                            default:
                                                return null
                                        }
                                    })()
                                }
                            </Box>
                        )
                    }
                    <Stack direction={'row'} justifyContent={'end'} spacing={2}>
                        <Button color={'error'} onClick={() => handleOpen('')}>
                            Indietro
                        </Button>
                        <Button variant={'contained'} type={'submit'}>
                            Salva
                        </Button>
                    </Stack>
                </Box>
            </DialogContent>
        </Box>
    );
}