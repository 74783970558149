import {CssBaseline, ThemeProvider} from "@mui/material";
import {Routes, Route, useLocation} from "react-router-dom";
import './App.css';
import {pleshTheme} from "./theme/pleshTheme";
import FrasiEvento from "./components/FrasiEvento";
import Home from "./pages/Home";
import {Event} from "./pages/Event";
import {interceptors} from "axios";
import {auth} from "./firebase/clientApp";

interceptors.request.use(
    async config => {
        const token = await auth.currentUser?.getIdToken()
        if (token) {
            config.headers.authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error)
    }
);

function App() {
    return (
        <ThemeProvider theme={pleshTheme}>
            <CssBaseline/>
            <Routes>
                <Route path={'/'} element={<Home/>}/>
                <Route path={'/events/:event_id'} element={<Event/>}/>
            </Routes>
        </ThemeProvider>
    );
}

export default App;
