import {createTheme} from "@mui/material";
import { itIT } from '@mui/x-data-grid';

export const pleshTheme = createTheme({
    components: {
        MuiLinearProgress: {
            styleOverrides:{
                root:{
                    width:'100% !important'
                }
            }
        }
    },

    palette: {
        mode: 'light',
        secondary:{
            main:'#fff'
        },
        info:{
            main:'#000'
        }
    },
}, itIT);

export const nivoTheme = {
    "tooltip": {
        "container": {
            "background": "#000000",
            "color": "#FFFFFF",
            "fontSize": 12
        },
        "basic": {},
        "chip": {},
        "table": {},
        "tableCell": {},
        "tableCellValue": {}
    }
}
