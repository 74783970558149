import React from "react";
import {Box, Button, TextField, Typography, useTheme} from "@mui/material";
import {Send} from "@mui/icons-material";
import axios from "axios";
import {API_URL} from "../../../../config";
import {useParams} from "react-router-dom";
import {CustomAlert} from "../../../shared/CustomAlert";

export const SendMessage = ({currentMessage}) => {
    const theme = useTheme()
    const {event_id} = useParams()
    const [message, setMessage] = React.useState(currentMessage)
    const [success, setSuccess] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [sending, setSending] = React.useState(false)

    const sendMessage = async (e) => {
        e.preventDefault()
        setSending(true)
        await axios.put(`${API_URL}events/${event_id}/message`, {message})
            .then((res) => {
                setSuccess(true)
            })
            .catch((err) => {
                setError(true)
            })
        setSending(false)
    }

    return (
        <Box>
            <CustomAlert open={success} severity={'success'} message={'Messaggio inviato correttamente'} handleClose={() => setSuccess(false)}/>
            <CustomAlert open={error} severity={'error'} message={'Errore durante l\'invio del messaggio'} handleClose={() => setError(false)}/>
            <Typography variant={"h4"} sx={{textAlign: 'left'}} fontWeight={'bold'}>
                Invio messaggio ai moderatori
            </Typography>
            <Box sx={{height: '4px', width: '12rem', background: theme.palette.primary.main}}/>
            <Box sx={{pt: 2}}>
                <TextField label={"Messaggio"} fullWidth multiline value={message} onChange={(e) => setMessage(e.target.value)}/>
            </Box>
            <Box sx={{pt:2, textAlign:'right'}}>
                <Button disabled={sending} endIcon={<Send/>} variant={'contained'} onClick={sendMessage}>
                    Invia
                </Button>
            </Box>
        </Box>
    )
}