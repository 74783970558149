import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDDJfHHQjDzMQ2JMtNsZYefwAF5KLXW2dU",
    authDomain: "lee-ai.firebaseapp.com",
    databaseURL: "https://lee-ai-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "lee-ai",
    storageBucket: "lee-ai.appspot.com",
    messagingSenderId: "611153384759",
    appId: "1:611153384759:web:2bd8cbab85ad5b48e9f6b2"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const firestore = firebase.firestore()
const auth = firebase.auth()

export {firestore, auth, firebase}
