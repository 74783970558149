import {
    Box, Button,
    Card, CardActions,
    CardContent,
    CardMedia,
    CircularProgress,
    Container,
    Grid, IconButton,
    Stack, Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Main} from "../layouts/Main";
import {auth, firestore} from "../firebase/clientApp";
import {Gavel, Insights} from "@mui/icons-material";
import {AddEvent} from "../components/AddEvent";
import {useCollection} from "react-firebase-hooks/firestore";

const Home = () => {
    const [events, eventsLoading, eventsError] = useCollection(firestore.collection('events'));
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                setUser(user)
            } else {
                setUser(null)
            }
        });
    }, []);

    return (
        <Main>
            <Container>
                {user && <AddEvent/>}
                <Grid container spacing={2} alignItems="stretch" justifyContent="start">
                    {!eventsLoading && events.docs.map((event) => {
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={event.id}>
                                <Card sx={{margin: '1rem', cursor: 'pointer'}}>
                                    <Box onClick={() => navigate('/events/' + event.id)}>
                                        <CardMedia
                                            component="img"
                                            style={{objectFit: 'contain'}}
                                            height="140"
                                            image={event.data().url_logo ?? 'https://i.vimeocdn.com/video/1189091216-94e083df54558192ca6bf1a4393b8c24baba1b9f386b3e68701ba3507ac61b90-d'}
                                        />
                                        <CardContent>
                                            <Typography fontWeight={'bold'}>{event.data().name}</Typography>
                                        </CardContent>
                                    </Box>
                                    <CardActions sx={{justifyContent: 'space-between'}}>
                                        <Button startIcon={<Gavel/>} size="small" variant={'contained'} component={'a'} href={`https://moderatori.plesh.co/${event.id}`} target={'_blank'}>moderatori</Button>
                                        <IconButton size="small" variant={'contained'} component={'a'} href={`https://real-time-report.plesh.co/${event.id}`} target={'_blank'}><Insights/></IconButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )
                    })}
                    {eventsLoading && <Stack alignItems="center" justifyContent="center" sx={{height: '70vh', width: '100%'}}>
                        <CircularProgress variant={"indeterminate"}/>
                    </Stack>}
                </Grid>
            </Container>
        </Main>
    )
}

export default Home;
