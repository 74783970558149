import React, {useEffect, useState} from "react";
import {
    AppBar,
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Stack, Toolbar,
    Typography,
    useTheme
} from "@mui/material";
import {Add, Close} from "@mui/icons-material";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {useParams} from "react-router-dom";
import {firestore} from "../../../firebase/clientApp";
import {SessionSettings} from "./EventSettings/SessionSettings";
import {SendMessage} from "./EventSettings/SendMessage";
import axios from "axios";
import {API_URL} from "../../../config";
import {CustomAlert} from "../../shared/CustomAlert";
import {LoaderButton} from "../../shared/LoaderButton";

export const EventSettings = ({handleOpen, open}) => {
    const {event_id} = useParams()
    const theme = useTheme()
    const [eventInfo, infoLoading, infoError] = useDocument(firestore.doc('events/' + event_id))
    const [currentSessions, loadingSessions, errorSessions] =
        useCollection(firestore.collection('events/' + event_id + '/sessions').orderBy('position'))
    const [sessions, setSessions] = useState([])
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [adding, setAdding] = useState(false)
    const [activating, setActivating] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (currentSessions && !loadingSessions && !errorSessions) {
            let _sessions = currentSessions.docs.map((s, index) => {
                let _session = {
                    id: s.id,
                    ...s.data(),
                }
                if (!s.position) {
                    _session.position = index
                }
                return _session
            })
            setSessions(_sessions)
        }
    }, [eventInfo, currentSessions])

    const addSession = async () => {
        setAdding(true)
        await axios.post(`${API_URL}events/${event_id}/sessions`, {position: sessions.length})
            .then((res) => {
                setSuccess(true)
            })
            .catch((err) => {
                setError(true)
            })
        setAdding(false)
    }

    const changeSession = async (id) => {
        setActivating(true)
        await axios.put(`${API_URL}events/${event_id}/change_session`, {session_id: id})
            .then((res) => {
                setSuccess(true)
            })
            .catch((err) => {
                setError(true)
            })
        setActivating(false)
    }

    if (infoLoading || loadingSessions) return (<Stack sx={{width: '100%', height: '100%'}}>
        <CircularProgress/>
    </Stack>)

    return (
        <Box>
            <CustomAlert open={success} severity={'success'} message={'Sessione aggiornata correttamente'}
                         handleClose={() => setSuccess(false)}/>
            <CustomAlert open={error} severity={'error'} message={'Errore durante l\'aggiornamento della sessione'}
                         handleClose={() => setError(false)}/>
            <AppBar sx={{position: 'relative'}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => handleOpen('')}
                        aria-label="close"
                    >
                        <Close/>
                    </IconButton>
                    <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                        Modifica le impostazioni dell'evento
                    </Typography>
                </Toolbar>
            </AppBar>
            <Grid container sx={{p: 1}} spacing={4}>
                <Grid item xs={12} md={8}>
                    <Box>
                        <Typography variant={"h4"} sx={{textAlign: 'left'}} fontWeight={'bold'}>
                            Sessione attiva
                        </Typography>
                        <Box sx={{height: '4px', width: '12rem', background: theme.palette.primary.main}}/>
                        <Box sx={{py: 2, position: 'relative'}}>
                            <Stack direction={'row'} spacing={2}>
                                {!loadingSessions && currentSessions.docs.map((s) =>
                                    <Button
                                        disabled={activating}
                                        variant={eventInfo.data()['current_session'].toString() === s.id ? 'contained' : 'outlined'}
                                        key={s.id} onClick={() => changeSession(s.id)}>
                                        {s.data().name}
                                    </Button>
                                )}
                            </Stack>
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant={"h4"} sx={{textAlign: 'left'}} fontWeight={'bold'}>
                            Impostazioni sessioni
                        </Typography>
                        <Box sx={{height: '4px', width: '12rem', background: theme.palette.primary.main}}/>
                        <Stack spacing={3} sx={{py: 2}}>
                            {
                                !loading && sessions.map((s, index) =>
                                    <SessionSettings key={index} _session={s}
                                                     currentSessions={currentSessions} eventInfo={eventInfo}/>
                                )
                            }
                            <Box position={'relative'}>
                                {adding && <LoaderButton/>}
                                <Button disabled={adding} variant={'outlined'} startIcon={<Add/>} onClick={addSession}
                                        sx={{width: '100%'}}>
                                    Aggiungi sessione
                                </Button>
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <SendMessage currentMessage={eventInfo.data().message}/>
                </Grid>
            </Grid>
        </Box>
    )
}