import React from "react";
import {Box, Button, Dialog, DialogContent, DialogTitle} from "@mui/material";
import {Add} from "@mui/icons-material";
import {EventForm} from "./Event/EventForm";

export const AddEvent = () => {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen( !open );

    return (
        <Box sx={{py:2}}>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth={'sm'} fullWidth>
                <DialogTitle>
                    Inserisci i dati dell'evento
                </DialogTitle>
                <DialogContent>
                    <EventForm handleOpen={handleOpen}/>
                </DialogContent>
            </Dialog>
            <Button startIcon={<Add/>} variant={'contained'} onClick={handleOpen}>
                Crea evento
            </Button>
        </Box>
    )
}