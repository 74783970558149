import React from "react";
import {Header} from "../components/shared/Header";
import {Box} from "@mui/material";

export const Main = ({children}) => {
  return(
      <Box>
          <Header/>
          {children}
      </Box>
  )
}