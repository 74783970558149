import React from "react";
import {Box, Stack, TextField, Typography, useTheme} from "@mui/material";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const fields = [
    {
        id:"name",
        name:"name",
        label:"Nome evento",
        type:"text",
    },
    {
        id:"num_participants",
        name:"num_participants",
        label:"Numero partecipanti",
        type:"number",
    },
    {
        id:"total_tables",
        name:"total_tables",
        label:"Numero tavoli",
        type:"number",
    },
    {
        id:"total_hours",
        name:"total_hours",
        label:"Numero ore",
        type:"number",
    },
    {
        id:"placeDateTime",
        name:"placeDateTime",
        label:"Data e luogo",
        type:"text",
    },
    {
        id:"max_sentence_len",
        name:"max_sentence_len",
        label:"Lunghezza massima frase",
        type:"number",
    },
    {
        id:"start_at",
        name:"start_at",
        label:"Data e ora inizio",
        type:"datetime",
    },
    {
        id:"end_at",
        name:"end_at",
        label:"Data e ora fine",
        type:"datetime",
    }
]

export const BasicInfo = ({handleChange, data}) => {
    const theme = useTheme()
    return (
        <Box>
            <Typography>
                Informazioni di base
            </Typography>
            <Box sx={{height: '4px', width: '4rem', background: theme.palette.primary.main}}/>
            <Stack spacing={2}>
            {
                fields.map((f,index) =>
                <Box key={f.id}>
                    {(() => {
                        switch (f.type) {
                            case "text":
                            case "number":
                                return (
                                    <TextField fullWidth label={f.label} variant={'standard'} required
                                               value={data[f.id]}
                                               name={f.name}
                                               onChange={e => handleChange(f.id, e.target.value)}
                                               helperText={f.id === 'name' ? `Id: ${data[f.id].toLowerCase().replaceAll(' ', '-')}` : null}
                                    />
                                )
                            case "datetime":
                                return (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DateTimePicker']}>
                                            <DateTimePicker
                                                label={f.label}
                                                value={data[f.id]}
                                                onChange={(newValue) => handleChange(f.id, newValue)}
                                                slotProps={{ textField: { required: true } }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                )
                            default:
                                return null
                        }
                    })()}
                </Box>
                )
            }
            </Stack>
        </Box>
    )
}