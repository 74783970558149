import axios from "axios";
import {API_URL} from "../config";

const getFrasi = (event_id, session_id) => {
    return axios.get(API_URL + "events/" + event_id + "/sessions/" + session_id + "/sentences")
};

const getParole = (sessione) => {
    return axios.get(API_URL + "data/" + sessione + "/parole")
};

const deleteFrase = (event_id, session_id, frase_id) => {
    return axios.delete(API_URL + "events/" + event_id + "/sessions/" + session_id + "/sentences/" + frase_id)
}

const getSummary = (event_id, session_id) => {
    return axios.get(API_URL + "summary/events/" + event_id + "/sessions/" + session_id)
};

const UserService = {
    getFrasi,
    getParole,
    deleteFrase,
    getSummary
};

export default UserService;
